import * as React from 'react'
import {Helmet} from 'react-helmet'

export const SEO =() => {
  return (
    <Helmet htmlAttributes={{translate: 'no'}}>
      <title>Margarita Sifnos Rooms and Studios</title>
      <meta name='keywords' content='Μαργαρίτα, Μανού, Καρύδη, ενοικιαζόμενα, δωμάτια, στούντιο, Απολλωνία, Καταβατή, Σίφνος'></meta>
      <meta name='description' content='Μαργαρίτα Μανού-Καρύδη Δωμάτια και Studios, Απολλωνία, Καταβατή, Σίφνος'></meta>
      <script async src='https://www.googletagmanager.com/gtag/js?id=UA-57535459-1'></script>
      <html translate='no' />
    </Helmet>
  )
}
